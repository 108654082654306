<template>
  <body class="main-bg min-h-screen flex justify-center items-start">
    <div class="container max-w-lg flex-col text-2xl font-sfpro_thin mt-16 lg:mt-32 text-center px-6 text-[#.23]">
      <img class="text-6xl font-black mb-8 max-w-[120px] lg:max-w-[170px] justify-self-center" src="./assets/logo.png">
        My name is Sam and I'm the Co-Founder of <span class="relative inline-block before:absolute before:bg-[#FF8D90] before:bottom-0 before:left-0 before:h-full before:w-full before:origin-bottom before:scale-y-[0.2] hover:before:scale-y-100 before:transition-transform before:ease-in-out before:duration-500"
        @mouseover="showIframe = true"
        @mouseleave="showIframe = false">
        <a href="https://www.numbersgame.uk" class="relative" target="_blank">Numbers Game</a><iframe
            v-if="showIframe"
            class="description absolute z-10 bg-white shadow-lg border-black border -inset-x-20 -inset-y-40"
            src="https://www.numbersgame.uk"
          ></iframe>
        </span>. I'm also a freelance creative
      and front-end developer, specialising in <span>
        <span class="relative inline-block before:absolute before:bg-[#FF8D90] before:bottom-0 before:left-0 before:h-full before:w-full before:origin-bottom before:scale-y-[0.20] hover:before:scale-y-100 before:transition-transform before:ease-in-out before:duration-500">
        <span class="relative" @mouseover="showDesign = true"
        @mouseleave="showDesign = false">
          <img v-if="showDesign" class="description absolute z-10 bg-white shadow-lg border-black border -inset-x-36 -inset-y-64 min-w-[400px]" src="./assets/before-webdesign.png">
          <a href="https://worldshoppingorganization.com/" class="relative" target="_blank">web design</a>
        </span>
      </span></span> & <span class="relative inline-block before:absolute before:bg-[#FF8D90] before:bottom-0 before:left-0 before:h-full before:w-full before:origin-bottom before:scale-y-[0.2] hover:before:scale-y-100 before:transition-transform before:ease-in-out before:duration-500">
        <span class="relative" @mouseover="showFrog = true"
        @mouseleave="showFrog = false">
        <a href="https://eateatthethefrogfrog.com/" class="relative" target="_blank">web development</a>
        <img v-if="showFrog" class="description absolute z-10 bg-white shadow-lg border-black border -inset-x-28 -inset-y-56 min-w-[400px]" src="./assets/frog-preview.png">
        </span></span>.<br><br>
        If you're looking to <span class="underline decoration-wavy underline-offset-[5px] decoration-[#c65693]">increase your revenue</span> through an elevated online presence or <span class="underline decoration-wavy underline-offset-[5px] decoration-[#c65693]">turn your business idea into a reality</span>, <span class="relative inline-block before:absolute before:bg-[#FF8D90] before:bottom-0 before:left-0 before:h-full before:w-full before:origin-bottom before:scale-y-[0.2] hover:before:scale-y-100 before:transition-transform before:ease-in-out before:duration-500">
        <span class="relative" @click="emailUs()">contact me</span>
      </span> and I can make those dreams come true.
    </div>
  </body>
</template>

<script>
export default {
  data () {
    return {
      showIframe: false,
      showDesign: false,
      showFrog: false
    }
  },
  methods: {
    emailUs () {
      const link = 'mailto:fletchersamuel96@gmail.com' + '?subject=' + encodeURIComponent('Website enquiry') + '&body=' + encodeURIComponent("Hi Sam,\n\n I'm interested in building a website.\n\n Are you free for an initial chat some time this week to discuss my idea?")
      window.location.href = link
    }
  }
}
</script>
